import React from 'react'
import { graphql } from 'gatsby'
import Button from '@material-ui/core/Button'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import SEO from 'components/elements/SEO/SEO'

const NotFound = () => {
  const { t } = useI18next()

  return (
    <>
      <SEO title={t('notFound.pageNotFound')} />
      <div className="closer" style={{ marginTop: '2rem' }}>
        <h3>
          <Trans>{t('notFound.pageNotFound')}</Trans>
        </h3>
        <p>
          <Trans>{t('notFound.pageNotExist')}</Trans>
        </p>
      </div>
      <p />
      <div className="closer">
        <AniLink to={`/`} direction="up" cover bg="#A22E37" duration={0.8}>
          <Button className="more-button notFound">
            <Trans>{t('notFound.backToHome')}</Trans>
          </Button>
        </AniLink>
      </div>
    </>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["translation"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default NotFound
